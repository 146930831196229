const state = {
  socket: null,
};

const getters = {};

const mutations = {
  setSocket(state, nuevoSocket) {
    state.socket = nuevoSocket;
    console.log(state.socket);
  },
};

const actions = {
    
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
