<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 p-0 footer-left">
          <p class="mb-0">Copyright © 2024 SuperKompras. All rights reserved.</p>
        </div>
        <div class="col-md-6 p-0 footer-right">
          <p class="mb-0">Desarrollado por </p>
          <a href="https://devitm.com/" target="_blank">
            <img src="../assets/images/logodevi.png" alt="DevITM" style="width: 100px; height: auto;">
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
