// const uploadProduct = 'http://localhost:3000/Api/V1/Productos/upload-images3';
// const updateProduct = 'http://localhost:3000/Api/V1/Productos/update-images';
// const removeProduct = 'http://localhost:3000/Api/V1/Productos/';
// const getProducts = 'http://localhost:3000/Api/V1/Productos/consulta-productos';
// const getProductsDays = 'http://localhost:3000/Api/V1/Productos/total-productos-ultimos-7-dias';
// // const getProductsfecha = 'http://localhost:3000/Api/V1/Productos/total-productos-feh'; 
// const getProductsfecha = 'http://localhost:3000/Api/V1/Productos/filtro-fecha';


const uploadProduct = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/upload-images3';
const updateProduct = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/update-images';
const removeProduct = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/';
const getProducts = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/consulta-productos';
const getProductsDays = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/total-productos-ultimos-7-dias';
const getProductsfecha = 'https://despliegueimagenessk.superkompras.com.mx/Api/V1/Productos/filtro-fecha';

export {uploadProduct, removeProduct, getProducts,updateProduct,getProductsDays,getProductsfecha}