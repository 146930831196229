<template>
    <Breadcrumbs title="Banco de Imagenes"/>
     <div class="container-fluid">
             <div class="row starter-main">
                <TablaProductos/>

             </div>
     </div>
  </template>
  <script>
import TablaProductos from '@/components/tablaProductos.vue';
  
  export default {
         components:{
               TablaProductos,  
         }
  }
  </script>