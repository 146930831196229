<template>
  <div class="account-user"><vue-feather type="user"></vue-feather></div>
  <ul class="profile-dropdown onhover-show-div">
    <li>{{ usuarioActual?.email }}</li>
    <li @click="logout">
      <a>
        <vue-feather type="log-in"></vue-feather><span>Log Out</span></a
      >
    </li>
  </ul>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapState("autenticacion", ["usuarioActual"]),
  },
  methods: {
    ...mapActions("autenticacion",["cerrarSesionUsuario"]),
    logout: function () {

      this.cerrarSesionUsuario();
      this.$router.push("/login")
    },
  },
};
</script>
