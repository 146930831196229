<template>
  <div class="loader-container">
    <div class="loader">
      
        <h3 class="loader-title">{{ tituloLoader ? tituloLoader : "loading"}}</h3>

        <p class="loader-text">
          {{ textoLoader ? textoLoader : ""}}
        </p>
      
      <span class="load"></span>
    </div>
  </div>
</template>
  
  <script>
  
  export default {
    props: ['textoLoader', 'tituloLoader'],
    name: 'LoaderCom'
  };
  </script>
  
  <style scoped>
  .loader {
    width: 80px;
    height: 50px;
    position: relative;
  }
  
  

  .loader-title{
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align:center;
    width: 20rem;
    padding: 0;
    margin: 0 0 .3rem 0;
    color: #6655e9;
    font-size: 1.6rem;
    letter-spacing: 1px;
  }

  .loader-text {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align:center;
    width: 20rem;
    padding: 0;
    margin: .5rem 0 0 0;
    color: #6655e9;
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  
  .load {
    background-color: #6655e9;
    border-radius: 50px;
    display: block;
    height: 16px;
    width: 16px;
    bottom: 0;
    position: absolute;
    transform: translateX(64px);
    animation: loading_713 3.5s ease both infinite;
  }
  
  .load::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #D1C2FF;
    border-radius: inherit;
    animation: loading2_713 3.5s ease both infinite;
  }
  .loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8); 
  z-index: 1000;
}
  
  @keyframes text_713 {
    0% {
      letter-spacing: 1px;
      transform: translateX(0px);
    }
    40% {
      letter-spacing: 2px;
      transform: translateX(26px);
    }
    80% {
      letter-spacing: 1px;
      transform: translateX(32px);
    }
    90% {
      letter-spacing: 2px;
      transform: translateX(0px);
    }
    100% {
      letter-spacing: 1px;
      transform: translateX(0px);
    }
  }
  
  @keyframes loading_713 {
    0% {
      width: 16px;
      transform: translateX(0px);
    }
    40% {
      width: 100%;
      transform: translateX(0px);
    }
    80% {
      width: 16px;
      transform: translateX(64px);
    }
    90% {
      width: 100%;
      transform: translateX(0px);
    }
    100% {
      width: 16px;
      transform: translateX(0px);
    }
  }
  
  @keyframes loading2_713 {
    0% {
      transform: translateX(0px);
      width: 16px;
    }
    40% {
      transform: translateX(0%);
      width: 80%;
    }
    80% {
      width: 100%;
      transform: translateX(0px);
    }
    90% {
      width: 80%;
      transform: translateX(15px);
    }
    100% {
      transform: translateX(0px);
      width: 16px;
    }
  }
  </style>
  