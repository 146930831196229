<template>
  <div class="row">
    <div class="col-md-3 mb-4">
      <div class="card h-100">
        <div class="card-header pb-0 text-center">
          <h3>Productos Ingresados</h3>
          <h5>Total</h5>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <h1 class="font-primary counter">{{ totalProductos }}</h1>
        </div>
      </div>
    </div>

    <div class="col-md-3 mb-4">
      <div class="card h-100">
        <div class="card-header pb-0 text-center">
          <h3>Productos Ingresados</h3>
          <h5>Últimos 7 días</h5>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <h1 class="font-primary counter">{{ totalProductosUltimos7Dias }}</h1>
        </div>
      </div>
    </div>

    <div class="col-md-3 mb-4">
      <div class="card h-100">
        <div class="card-header pb-0 text-center">
          <h3>Productos sin Foto en la APP</h3>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <h1 class="font-primary counter">{{ productosSinFoto }}</h1>
        </div>
      </div>
    </div>

    <div class="col-md-3 mb-4">
      <div class="card h-100">
        <div class="card-header pb-0 text-center">
          <h3>Productos con Foto en la APP</h3>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <h1 class="font-primary counter">{{ productosConFoto }}</h1>
        </div>
      </div>
    </div>

    <div class="col-md-3 mb-4">
      <div class="card h-100">
        <div class="card-header pb-0 text-center">
          <h3>Banco de Imágenes</h3>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <router-link to="/banco-imagenes">
            <i class="fas fa-images fa-4x" style="color: #007bff;"></i>
          </router-link>
        </div>
      </div>
    </div>

    <div class="col-md-3 mb-4">
      <div class="card h-100">
        <div class="card-header pb-0 text-center">
          <h3>Subir Producto</h3>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <router-link to="/subir-productos">
            <i class="fas fa-cloud-upload-alt fa-4x" style="color: #007bff;"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getProducts, getProductsDays } from '@/store/apibase';

export default {
  name: 'CardComponent',
  data() {
    return {
      totalProductos: 0,
      totalProductosUltimos7Dias: 0,
      productosSinFoto: 0,
      productosConFoto: 0,
    };
  },
  mounted() {
    this.TotalProductos();
    this.TotalProductosUltimos7Dias();
    this.obtenerProductosSinFoto();
    this.obtenerProductosConFoto();
  },
  methods: {
    //Esta funcion nos calcula el total de productos y nos manda la cantidad en la pagina principal
    async TotalProductos() {
      try {
        const response = await axios.get(getProducts);

        this.totalProductos = response.data.data.length;
        console.log(response.data);
      } catch (error) {
        console.error('ERROR', error);
      }
    },
    //Esta funcion nos calcula el total de productos agregados de los ultimos 7 dias y nos manda la cantidad en la pagina principal
    async TotalProductosUltimos7Dias() {
      try {
        const response = await axios.get(getProductsDays);
        this.totalProductosUltimos7Dias = response.data.total;
      } catch (error) {
        console.error('ERROR', error);
      }
    },
    async obtenerProductosSinFoto() {
      try {
        const response = await axios.post(
          'https://first-liger-32.hasura.app/api/rest/getproductRFC',
          { Foto: 'NO' },
          {
            headers: {
              "Content-Type": "application/json",
              "x-hasura-admin-secret": "VU3Kz5uhENDhpFEOGRn6ezpxF7P6Aal8kSJ4at761WIB2oxeRzxkICjFYaGXLUV1",
            },
          }
        );
        // Asignar el número de productos sin foto basados en la respuesta JSON
        this.productosSinFoto = response.data.Prod_prov_foto.length;
      } catch (error) {
        console.error('ERROR al obtener productos sin foto:', error);
      }
    },
    async obtenerProductosConFoto() {
      try {
        const response = await axios.post(
          'https://first-liger-32.hasura.app/api/rest/getproductRFC',
          { Foto: 'SI' },
          {
            headers: {
              "Content-Type": "application/json",
              "x-hasura-admin-secret": "VU3Kz5uhENDhpFEOGRn6ezpxF7P6Aal8kSJ4at761WIB2oxeRzxkICjFYaGXLUV1",
            },
          }
        );
        // Asignar el número de productos sin foto basados en la respuesta JSON
        this.productosConFoto = response.data.Prod_prov_foto.length;
      } catch (error) {
        console.error('ERROR al obtener productos sin foto:', error);
      }
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-header {
  background-color: #f8f9fa;
}

.fas.fa-images,
.fas.fa-cloud-upload-alt {
  font-size: 4rem;
}
</style>
