<template>
  <Breadcrumbs title="Consultar Usuarios" />
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="mb-3">
            <label class="form-label" for="nombre">Filtrado por nombre:</label>
            <input
              class="form-control"
              id="nombre"
              type="text"
              placeholder="Introduce el nombre del usuario"
              v-model.trim="nombreSeleccionado"
              @input="filtrarUsuarios()"
            />
          </div>
        </div>

        <div class="col-lg-4 col-md-12">
          <div class="mb-3">
            <label class="form-label" for="correo">Filtrado por correo:</label>
            <input
              class="form-control"
              id="correo"
              type="email"
              placeholder="Introduce un correo electrónico"
              v-model.trim="correoSeleccionado"
              @input="filtrarUsuarios()"
            />
          </div>
        </div>

        <div class="col-lg-4 col-md-12">
          <div class="mb-3">
            <label class="form-label" for="rol">Filtrado por rol:</label>
            <select
              class="form-select digits"
              id="rol"
              v-model.trim="rolSeleccionado"
            >
              <option>Todos</option>
              <option
                v-for="rol in rolesConsultados"
                :value="rol.id"
                :key="rol.id"
              >
                {{ rol.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="mb-3">
            <label class="form-label" for="rol">Filtrado por Estatus:</label>
            <select
              class="form-select digits"
              id="rol"
              v-model.trim="estatusSeleccionado"
            >
              <option>Todos</option>
              <option value="active">Activo</option>
              <option value="inactive">Inactivo</option>
            </select>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-12 d-flex align-items-end justify-content-center"
        >
          <div class="mb-3">
            <RouterLink
              to="/alta-usuarios"
              class="btn btn-primary d-flex justify-content-center gap-2 align-items-center"
              type="button"
            >
              <span>Dar de alta un usuario</span>
              <vue-feather type="user-plus" />
            </RouterLink>
          </div>
        </div>
      </div>
    </div>

    <TablaUsuarios
      :dataTabla="usuariosFiltrados"
      @mostrarModal="mostrarModalActualizar"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TablaUsuarios from "../components/tablaUsuarios.vue";

export default {
  data() {
    return {
      usuariosConsultados: [],
      usuariosFiltrados: [],
      rolesConsultados: [],
      exampleData: [
        {
          id: 1,
          nombre: "Aaron",
          correo: "aaron.isaac.echavarria@outlook.com",
          rol: "TI",
          estatus: "Activo",
        },
      ],
      nombreSeleccionado: "",
      correoSeleccionado: "",
      rolSeleccionado: "Todos",
      estatusSeleccionado: "Todos",
    };
  },
  components: {
    TablaUsuarios,
  },
  async mounted() {
    this.revisarCorreoAlta();
    try {
      await this.consultarUsuarios();
      await this.consultarRoles();
    } catch (error) {
      this.$swal({
        icon: error.msg ? "error" : "warning",
        title: error.code ? error.code : "Error",
        text: error.msg ? error.msg : null,
      });
    }
  },
  computed: {
    ...mapState("autenticacion", ["usuarios", "roles"]),
  },
  watch: {
    usuarios: {
      handler(nuevosUsuarios, viejosUsuarios) {
        console.log("Actualizando");
        console.log(nuevosUsuarios);
        this.usuariosConsultados = nuevosUsuarios;
        this.filtrarUsuarios();
      },
      deep: true,
    },
    roles: {
      handler(nuevosRoles, viejosRoles) {
        this.rolesConsultados = nuevosRoles;
      },
      deep: true,
    },
    rolSeleccionado(nuevoRol) {
      this.filtrarUsuarios();
    },
    estatusSeleccionado(nuevoRol) {
      this.filtrarUsuarios();
    },
  },
  methods: {
    ...mapActions("autenticacion", [
      "consultarUsuarios",
      "consultarRoles",
      "actualizarDatosUsuarioBD",
    ]),
    revisarCorreoAlta() {
      if (this.$route.params.correo) {
        this.correoSeleccionado = this.$route.params.correo;
      }
    },
    async actualizarUsuario(nombre, rol, estatus, id) {
      try {
        await this.actualizarDatosUsuarioBD({
          nombre: nombre,
          rol: rol,
          estatus: estatus,
          uid: id,
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.msg ? "error" : "warning",
          title: error.code ? error.code : "Error",
          text: error.msg ? error.msg : null,
        });
      }
    },
    filtrarUsuarios() {
      this.usuariosFiltrados = this.usuariosConsultados;
      if (this.nombreSeleccionado) {
        this.usuariosFiltrados = this.usuariosFiltrados.filter((usuario) => {
          return usuario.name
            .toLowerCase()
            .includes(this.nombreSeleccionado.toLowerCase());
        });
      }
      if (this.correoSeleccionado) {
        this.usuariosFiltrados = this.usuariosFiltrados.filter((usuario) => {
          return usuario.email
            .toLowerCase()
            .includes(this.correoSeleccionado.toLowerCase());
        });
      }
      if (this.rolSeleccionado && this.rolSeleccionado != "Todos") {
        this.usuariosFiltrados = this.usuariosFiltrados.filter((usuario) => {
          return usuario.rol + "" == this.rolSeleccionado + "";
        });
      }
      if (this.estatusSeleccionado && this.estatusSeleccionado != "Todos") {
        this.usuariosFiltrados = this.usuariosFiltrados.filter((usuario) => {
          return usuario.status + "" == this.estatusSeleccionado + "";
        });
      }
    },
    mostrarModalActualizar(dataUsuario) {
      console.log(dataUsuario);
      this.$swal({
        title: "Edición rapida",
        html: `
    <div class="mb-3">
      <label for="actNombre">Nombre</label>
      <input type="text" id="actNombre" class="swal2-input d-block m-auto" placeholder="Nombre" value="${
        dataUsuario.name
      }">
    </div>
    <div class="mb-3">
      <label for="actRol">Rol</label>
      <select id="actRol" class="swal2-select d-block m-auto">
        <option value="1" ${
          dataUsuario.rol.toString() === "1" ? "selected" : ""
        }>Administrador</option>
        <option value="2" ${
          dataUsuario.rol.toString() === "2" ? "selected" : ""
        }>Compras</option>
        <option value="3" ${
          dataUsuario.rol.toString() === "3" ? "selected" : ""
        }>TI</option>
        <option value="4" ${
          dataUsuario.rol.toString() === "4" ? "selected" : ""
        }>E-commerce</option>
      </select>
    </div>
    <div class="mb-3">
      <label for="actEstatus">Estatus</label>
      <select id="actEstatus" class="swal2-select d-block m-auto">
        <option value="active" ${
          dataUsuario.status === "active" ? "selected" : ""
        }>Activo</option>
        <option value="inactive" ${
          dataUsuario.status === "inactive" ? "selected" : ""
        }>Inactivo</option>
      </select>
    </div>
  `,
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const nombre = document.getElementById("actNombre").value;
          const rol = document.getElementById("actRol").value;
          const estatus = document.getElementById("actEstatus").value;

          console.log(nombre);
          console.log(rol);
          console.log(estatus);
          console.log(dataUsuario);

          this.actualizarUsuario(nombre, rol, estatus, dataUsuario.id);
        }
      });
    },
  },
};
</script>
