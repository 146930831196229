<template>
  <div class="col-sm-12">
    <div class="card-body">
      <div class="table-responsive theme-scrollbar data-table">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col" sortable="name">Id</th>
              <th scope="col" sortable="startDate">Nombre</th>
              <th scope="col" sortable="invoice">Correo</th>
              <th scope="col" sortable="position">Rol</th>
              <th scope="col" sortable="credit">Estatus</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="row in get_rows" :key="row">
              <td scope="row">
                {{ row.id }}
              </td>
              <td>{{ row.name }}</td>
              <td>{{ row.email }}</td>
              <td>
                <div class="py-1 px-3 rounded text-white d-flex justify-content-center" :style="{'background-color': coloresRoles[row.rol].color}">
                  {{ coloresRoles[row.rol].nombre }}
                </div>
              </td>
              <td >
                <div class="py-1 px-3 rounded text-white d-flex justify-content-center" :style="{'background-color': coloresEstatus[row.status].color}">
                  {{ coloresEstatus[row.status].texto }}
                </div>
              </td>
              <td >
                <div class="d-flex justify-content-center align-items-center">

                  <button class="btn btn-primary px-3 pb-2" type="button" @click="actualizarUsuario(row)">
                    <vue-feather type="edit" size="15"></vue-feather>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ul
        class="pagination pagination-primary p-2 d-flex justify-content-end mt-3"
        v-if="num_pages() > 0"
      >
        <li class="page-item" v-if="currentPage != 1">
          <a class="page-link" @click="change_page(1)"
            >&laquo;</a
          >
        </li>
        <li class="page-item" v-if="currentPage != 1">
          <a class="page-link" @click="change_page(currentPage - 1)"
            >Anterior</a
          >
        </li>
        <li
          v-if="(currentPage - 2) > 0"
          :class="[ (currentPage - 2) - 1 < 1 ? 'page-item' : '']"
          v-on:click=" (currentPage - 2) - 1 < 1 ? change_page(currentPage - 2) : null"
          :key="currentPage - 2"
        >
          <a class="page-link">{{ (currentPage - 2) - 1 < 1 ? currentPage - 2 : "..." }}</a>
        </li>
        <li
          v-if="(currentPage - 1) > 0"
          class="page-item"
          v-on:click="change_page(currentPage - 1)"
          :key="currentPage - 1"
        >
          <a class="page-link">{{ currentPage - 1 }}</a>
        </li>
        <li
          class="page-item active"
          v-on:click="change_page(currentPage)"
          :key="currentPage"
        >
          <a class="page-link">{{ currentPage}}</a>
        </li>
        <li
          v-if="(currentPage + 1) <= num_pages()"
          class="page-item"
          v-on:click="change_page(currentPage + 1)"
          :key="currentPage + 1"
        >
          <a class="page-link">{{ currentPage + 1 }}</a>
        </li>
        <li
        v-if="(currentPage + 2) <= num_pages()"
          :class="[ num_pages() - (currentPage + 2) < 1 ? 'page-item' : '']"
          v-on:click=" num_pages() - (currentPage + 2) < 1 ? change_page(currentPage + 2) : null"
          :key="currentPage + 2"
          disabled
        >
          <a class="page-link">{{ num_pages() - (currentPage + 2) < 1 ? currentPage + 2 : "..." }}</a>
        </li>
        <li class="page-item" v-if="currentPage != num_pages()">
          <a class="page-link" @click="change_page(currentPage + 1)"
            >Siguiente</a
          >
        </li>
        <li class="page-item" v-if="currentPage != num_pages()">
          <a class="page-link" @click="change_page(num_pages())"
            >&raquo;</a
          >
        </li>
      </ul>
      </div>
    </div>
  </div>
</template>
<script>
import imageMixin from "@/mixins/commen/imageMixin";
import { mapState } from "vuex";
export default {
  mixins: [imageMixin],
  props: ["dataTabla"],
  data() {
    return {
      elementsPerPage: 6,
      currentPage: 1,
      ascending: false,
      sortColumn: "",
      perPage: 20,
      pageOptions: [10, 25, 50, 100],
      filterQuery: "",
      allData: [],
      coloresRoles: {
        1: {
          color: "#F97316",
          nombre: "Administrador",
        },

        2: {
          color: "#3B82F6",
          nombre: "Compras",
        },
        3: {
          color: "#7E22CE",
          nombre: "TI",
        },
        4: {
          color: "#EAB308",
          nombre: "E-commerce",
        },
      },
      coloresEstatus: {
        active: {
          color: "#10B981",
          texto: "Activo"
        },
        inactive: {
          color: "#E11D48",
          texto: "Inactivo"
        },
      },
    };
  },
  watch: {
    dataTabla(newdata) {
      this.allData = newdata;
    },
  },
  //   watch: {
  //     filterQuery(search) {
  //       var filteredData = this.tableItems.filter((row) => {
  //         return (
  //           row.name.toLowerCase().includes(search.toLowerCase()) ||
  //           row.position.toLowerCase().includes(search.toLowerCase()) ||
  //           row.office.toLowerCase().includes(search.toLowerCase()) ||
  //           row.salary.toLowerCase().includes(search.toLowerCase()) ||
  //           row.Budget.toLowerCase().includes(search.toLowerCase())
  //         );
  //       });
  //       search == ""
  //         ? (this.allData = this.tableItems)
  //         : (this.allData = filteredData);
  //     },
  //   },
  computed: {
    ...mapState({
      tableItems: (state) => state.datatable.items,
    }),
    columns() {
      if (this.allData.length == 0) {
        return [];
      }
      return Object.keys(this.allData[0]);
    },
    get_rows() {
      var start = (this.currentPage - 1) * this.elementsPerPage;
      var end = start + this.elementsPerPage;
      return this.allData.slice(start, end);
    },
  },
  methods: {
    removeProduct: function (index) {
      this.tableItems.splice(index, 1);
    },
    num_pages() {
      return Math.ceil(this.allData.length / this.elementsPerPage);
    },
    change_page(page) {
      this.currentPage = page;
    },
    actualizarUsuario(row){
      this.$emit("mostrarModal", row);
    }
  },
};
</script>
