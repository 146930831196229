<template>
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-3">
                        <label>Filtrado Por Producto:</label>
                        <input class="form-control" v-model="filtros.producto" type="text" placeholder="Producto"
                            @change="consultarProductos" />
                    </div>
                    <div class="col-lg-4 col-12 mb-3">
                        <label class="form-label">Cargar Excel:</label>
                        <div class="input-group">
                            <input type="file" @change="cargarExcel" accept=".xlsx, .xls" class="form-control"
                                id="inputGroupFile" />
                        </div>
                    </div>

                    <div class="col-lg-4 col-12 mb-3">
                        <label>Filtrado Por Fecha:</label>
                        <datepicker class="datepicker-here digits" range id="fechaPedidos" :enable-time-picker="false"
                            type="date" locale="es" format="dd/MM/yyyy" select-text="Confirmar" cancel-text="Cancelar"
                            v-model="filtros.fecha" @selected="handleFechaSeleccionada" />
                    </div>
                    <div class="col-lg-4 col-12 mb-3">
                        <label>Filtrado Por Resolucion:</label>
                        <select class="form-select digits" v-model="filtros.resolucion">
                            <option value="">Todas</option>
                            <option value="red128">128X128</option>
                            <option value="red256">256X256</option>
                            <option value="red512">512X512</option>
                            <option value="red1024">1024X1024</option>
                        </select>
                    </div>
                    <div class="col-lg-4 col-12 mb-3">
                        <label>Redimensionada:</label>
                        <select class="form-select digits" v-model="filtros.redimensionada">
                            <option value="">Ambas</option>
                            <option value="1">SI</option>
                            <option value="0">NO</option>
                        </select>
                    </div>
                    <div class="col-lg-4 col-12 mb-3">
                        <button @click="consultarProductos" class="btn btn-primary mb-3"
                            style="margin-right: 10px;">Consultar</button>
                        <button @click="descargarInforme" class="btn btn-primary mb-3">Descargar Informe</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive theme-scrollbar">
                <table class="display table-striped table-hover table-bordered table">
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Imagen</th>

                            <th>Fecha</th>
                            <th>RED128</th>
                            <th>RED256</th>
                            <th>RED512</th>
                            <th>RED1024</th>
                            <th>IMG128</th>
                            <th>IMG256</th>
                            <th>IMG512</th>
                            <th>IMG1024</th>
                            <th style="display: flex; justify-content: center">ACCIONES</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="producto in paginatedProductos" :key="producto.id">
                            <td>{{ producto.producto }}</td>
                            <td>
                                <img :src="producto.url" alt="Imagen del producto"
                                    style="max-width: 100px; max-height: 100px;">
                            </td>

                            <td>{{ formatoFecha(producto.fecha) }}</td>
                            <td>{{ producto.red128 ? 'Disponible' : 'No Redimensionado' }}</td>
                            <td>{{ producto.red256 ? 'Disponible' : 'No Redimensionado' }}</td>
                            <td>{{ producto.red512 ? 'Disponible' : 'No Redimensionado' }}</td>
                            <td>{{ producto.red1024 ? 'Disponible' : 'No Redimensionado' }}</td>
                            <td><img :src="producto.url128" alt="No Redimensionado"
                                    style="max-width: 100px; max-height: 100px;"></td>
                            <td><img :src="producto.url256" alt="No Redimensionado"
                                    style="max-width: 100px; max-height: 100px;"></td>
                            <td><img :src="producto.url512" alt="No Redimensionado"
                                    style="max-width: 100px; max-height: 100px;"></td>
                            <td><img :src="producto.url1024" alt="No Redimensionado"
                                    style="max-width: 100px; max-height: 100px;"></td>

                            <td
                                style="display: flex; justify-content: center; align-items: center; height: 117px; width: 300px;">
                                <button @click="mostrarAlertaDetallesProducto(producto)"
                                    class="btn btn-success btn-xs me-2" type="button" title="Actualizar">
                                    <vue-feather type="edit" />
                                    Actualizar
                                </button>
                                <button @click="mostrarOpcionesDescarga(producto)" class="btn btn-primary btn-xs me-2"
                                    type="button" title="Descargar">
                                    <vue-feather type="save" />
                                    Descargar
                                </button>
                                <button @click="confirmarRemoverProducto(producto)" class="btn btn-danger btn-xs me-2"
                                    type="button" title="Remover">
                                    <vue-feather type="trash-2" />
                                    Remover
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="pagination pagination-primary p-2 d-flex justify-content-end align-items-center mt-3">
                    <button class="btn btn-primary btn-xs me-3" @click="previousPage" :disabled="currentPage === 1">
                        Anterior
                    </button>
                    <span class="mx-3">Página {{ currentPage }} de {{ totalPages }}</span>
                    <button class="btn btn-primary btn-xs ms-3" @click="nextPage"
                        :disabled="currentPage === totalPages">
                        Siguiente
                    </button>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as XLSX from "xlsx";
import { PDFDocument, rgb } from 'pdf-lib';
import axios from 'axios';
import Swal from "sweetalert2";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { sweetAlertMixin } from "../mixins/sweetAlertMixin.js";
import { removeProduct, getProducts, updateProduct } from '@/store/apibase';

export default {
    mixins: [sweetAlertMixin],
    data() {
        return {
            productosEncontrados: [],
            cbColumn: [], // Los CB del Excel
            productosExcel: [], // Los productos del endpoint
            productos: [],
            images: [],
            filtros: {
                resolucion: '',
                redimensionada: '',
                producto: '',
                fecha: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]
            },
            currentPage: 1,
            itemsPerPage: 20,
            locale: 'es',
        };
    },
    computed: {
        ...mapState("autenticacion", ["usuarioActual"]),
        paginatedProductos() {
            if (this.productos.data) {
                const start = (this.currentPage - 1) * this.itemsPerPage;
                const end = start + this.itemsPerPage;
                return this.productos.data.slice(start, end);
            } else {
                return [];
            }
        },
        totalPages() {
            if (this.productos.data) {
                return Math.ceil(this.productos.data.length / this.itemsPerPage);
            } else {
                return 0;
            }
        }

    },
    methods: {
        ...mapActions("autenticacion", ["cerrarSesionUsuario"]),
        async cargarExcel(event) {
            const file = event.target.files[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // Obtenemos la primera hoja del archivo Excel
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                this.cbColumn = jsonData.map(row => row.cb);
                //console.log('CB del archivo:', this.cbColumn);

                this.getProducts();
            };
            reader.readAsArrayBuffer(file);
        },
        async getProducts() {
            try {
                const response = await axios.get(getProducts);
                this.productosExcel = response.data.data;
                //console.log('Productos recibidos:', this.productosExcel);

                // Comparamos los productos con los cb del Excel
                this.compareCBWithProducts();
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        },
        compareCBWithProducts() {
            try {
                const productosEncontrados = this.productosExcel.filter(producto =>
                    this.cbColumn.some(cb =>
                        cb && producto.producto &&
                        cb.toString().trim() === producto.producto.toString().trim()
                    )
                );

                if (productosEncontrados.length > 0) {
                    //console.log('Productos que coinciden con CB del archivo:', productosEncontrados);
                    this.exportarExcel(productosEncontrados);
                } else {
                    //console.log('No se encontraron coincidencias.');

                    // Mostrar SweetAlert si no hay coincidencias
                    this.$swal({
                        title: "Sin coincidencias",
                        text: "No se encontraron coincidencias entre los CB del archivo Excel y los productos.",
                        icon: "warning",
                        confirmButtonText: "Aceptar"
                    });
                }
            } catch (error) {
                console.error('Error durante la comparación de productos:', error);
            }
        },
        async exportarExcel() {
            try {
                const datos = this.cbColumn.map(cbFromExcel => {
                    // Buscar si el CB existe en los productos obtenidos del endpoint
                    const productoEncontrado = this.productosExcel.find(producto => producto.producto.toString().trim() === cbFromExcel.toString().trim());

                    const fotoDisponibilidad = productoEncontrado ? 'SI' : 'NO';

                    return {
                        cb: cbFromExcel.toString().trim(),
                        foto: fotoDisponibilidad
                    };
                });

                const worksheet = XLSX.utils.json_to_sheet(datos);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Reporte de Productos');
                const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

                // Obtener la fecha actual
                const hoy = new Date();
                const dia = String(hoy.getDate()).padStart(2, '0');
                const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados
                const anio = hoy.getFullYear();

                // Formato de fecha: dd-mm-yyyy
                const fechaHoy = `${dia}-${mes}-${anio}`;

                // Crear un enlace para descargar el archivo
                const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `Reporte banco de imágenes Tiendas Garcés-${fechaHoy}.xlsx`;
                link.click();

                // Mostrar SweetAlert de éxito
                this.$swal({
                    title: "Reporte Generado correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar"
                });

            } catch (error) {
                console.error('Error al generar el archivo Excel:', error);

                // Mostrar SweetAlert de error
                this.$swal({
                    title: "Error",
                    text: "Hubo un problema al generar el archivo Excel. Por favor, inténtalo de nuevo.",
                    icon: "error",
                    confirmButtonText: "Aceptar"
                });
            }
        },
        handleFechaSeleccionada(date) {
            this.filtros.fecha = date;
        },
        consultarProductos() {
            //console.log('Filtros:', this.filtros); // Verifica qué valores están en filtros, incluyendo fecha

            let params = { ...this.filtros }; // Clonar filtros para evitar modificar el objeto original

            if (this.filtros.fecha) {
                const fechaInicio = this.filtros.fecha[0].toISOString(); // Convertir a formato ISO
                const fechaFin = this.filtros.fecha[1].toISOString();

                params.startDate = fechaInicio; // Agregar startDate al objeto de parámetros
                params.endDate = fechaFin; // Agregar endDate al objeto de parámetros
            }

            axios.get(getProducts, { params })
                .then(response => {
                    this.productos = response.data;
                    this.currentPage = 1;
                    //console.log('Datos recibidos:', response.data);
                    // Mostrar SweetAlert después de obtener los datos
                    this.$swal({
                        title: "Consulta Exitosa",
                        html: `
                    <div class="mb-3">
                        <p>Se han encontrado ${response.data.data.length} productos.</p>
                    </div>
                `,
                        icon: "success", // Puedes cambiarlo a "info", "warning", "error", según lo que necesites
                        confirmButtonText: "Aceptar"
                    });
                })
                .catch(error => {
                    console.error('Error al obtener datos:', error);
                    // Mostrar SweetAlert en caso de error
                    this.$swal({
                        title: "Error en la Consulta",
                        text: "No se pudo obtener los productos. Por favor, intenta nuevamente.",
                        icon: "error",
                        confirmButtonText: "Aceptar"
                    });
                });
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        formatoFecha(fecha) {
            if (!fecha) {
                return "Fecha no disponible";
            }
            const fechaParseada = Date.parse(fecha.replace(' ', 'T'));
            if (!isNaN(fechaParseada)) {
                return new Date(fechaParseada).toLocaleDateString();
            } else {
                return "Fecha no válida";
            }
        },
        descargarInforme() {
            //esta funcion nos ayuda para poder descargar nuestro reporte en formato csv 
            const tableData = this.productos.data.map(producto => ({
                Producto: producto.producto,
                UserId: producto.userId,
                Fecha: this.formatoFecha(producto.fecha),
                'Fecha Actualizacion': producto['fecha-actualizado'],
                'UserId Actualizacion': producto['userId-actualizado'],
                RED128: producto.red128 ? 'Disponible' : 'No Redimensionado',
                RED256: producto.red256 ? 'Disponible' : 'No Redimensionado',
                RED512: producto.red512 ? 'Disponible' : 'No Redimensionado',
                RED1024: producto.red1024 ? 'Disponible' : 'No Redimensionado',
                'URL 128': producto.url128,
                'URL 256': producto.url256,
                'URL 512': producto.url512,
                'URL 1024': producto.url1024
            }));
            this.$exportTableToExcel(tableData, 'InformeProductos.xlsx');
        },
        confirmarRemoverProducto(producto) {
            // la funcion que nos muestra un sweetalert para confirmar la eliminacion de un producto si confirmamos nos ejecuta la funcion removerProducto
            this.showAlert(
                "¿Estás seguro de ELIMINAR este producto?",
                "",
                "",
                "Sí, remover",
                "Cancelar",
                producto.url
            ).then((result) => {
                if (result.isConfirmed) {
                    this.removerProducto(producto);
                } else {
                    //console.log("Eliminación cancelada por el usuario");
                }
            });
        },
        removerProducto(producto) {
            // la funcion para eliminar un producto de la base de datos tanto de mariaDB como de Hasura
            axios.delete(`${removeProduct}${producto.producto}`)
                .then(response => {
                    //console.log("Producto eliminado correctamente");
                    //console.log("Registro eliminado de Hasura correctamente");
                    this.consultarProductos();

                })
                .catch(error => {
                    console.error('Error al eliminar el producto:', error);
                });
        },
        mostrarOpcionesDescarga(producto) {
            // la funcion que nos muestra un sweetalert para los tipos de descarga de cada imagen 
            Swal.fire({
                title: 'Selecciona una opción de descarga',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Descargar Original',
                cancelButtonText: 'Descargar Original y Redimensiones',
                confirmButtonClass: 'mb-3 btn btn-primary',
                cancelButtonClass: 'btn btn-primary',
                buttonsStyling: false,

            }).then((result) => {
                if (result.isConfirmed) {
                    this.descargarImagen(producto);
                } else {
                    this.descargarPaquete(producto);
                }
            });
        },
        descargarImagen(producto) {
            //funcion para descargar una imagen original en su tamaño original
            const url = producto.url;
            const nombreArchivo = `${producto.producto}.jpg`;

            axios.get(url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], { type: response.data.type });
                    FileSaver.saveAs(blob, nombreArchivo);
                    //console.log("imagen descargada");
                })
                .catch(error => {
                    console.error('Error al descargar la imagen:', error);
                });
        },
        descargarPaquete(producto) {
            //funcion para descargar un paquete de imagenes en todas las dimensiones
            const zip = new JSZip();
            const urls = [
                { url: producto.url, name: `${producto.producto}.jpg` },
                { url: producto.url128, name: `${producto.producto}-128X128.jpg` },
                { url: producto.url256, name: `${producto.producto}-256X256.jpg` },
                { url: producto.url512, name: `${producto.producto}-512X512.jpg` },
                { url: producto.url1024, name: `${producto.producto}-1024X1024.jpg` }
            ];

            const promesas = urls.map(item => {
                if (item.url) {
                    return axios.get(item.url, { responseType: 'blob' })
                        .then(response => zip.file(item.name, response.data))
                        .catch(error => {
                            console.error(`${item.name} no redimensionada`);

                        });
                } else {
                    return Promise.resolve();
                }
            });

            Promise.all(promesas)
                .then(() => {
                    zip.generateAsync({ type: 'blob' })
                        .then(content => {
                            FileSaver.saveAs(content, `${producto.producto}.zip`);
                            //console.log("Paquete descargado correctamente.");
                        });
                })
                .catch(error => {
                    console.error('Error al descargar el paquete:', error);
                });
        },
        mostrarAlertaDetallesProducto(producto) {
            //Sweet alert que nos manda el detalle de un producto e igual nos ayuda para poder hacer una actualizacion de dicha imagen
            this.$swal({
                title: "Detalles del Producto",
                html: `
                <div class="mb-3">
                    <img src="${producto.url}" alt="Imagen del producto" style="max-width: 100px; max-height: 100px;">
                </div>
                <div class="mb-3">
                    <label for="inputProducto">${producto.producto}</label>
                </div>
                <div class="mb-3">
                    <input type="file" id="fileInput" class="form-control" />
                </div>
                <div class="mb-3">
                    <button id="uploadButton" class="btn btn-outline-primary">Actualizar Imagen</button>
                </div>
            `,
                showCancelButton: false,
                confirmButtonText: "Listo",
                didOpen: () => {
                    const fileInput = document.getElementById('fileInput');
                    const uploadButton = document.getElementById('uploadButton');
                    const uid = this.usuarioActual?.uid;

                    uploadButton.addEventListener('click', () => {
                        const file = fileInput.files[0];
                        if (file) {
                            const fileName = file.name;
                            const fileBaseName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;

                            if (fileBaseName === producto.producto) {
                                this.uploadImage(file, uid);
                            } else {
                                this.$swal('Error', 'El nombre del archivo debe coincidir con el nombre del producto.', 'error');
                            }
                        } else {
                            this.$swal('Error', 'Por favor selecciona una imagen primero.', 'error');
                        }
                    });
                }
            });
        },
        async uploadImage(file, uid) {
            const fileName = file.name;
            const productName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;

            const formData = new FormData();
            formData.append('files', file);
            formData.append('uid', uid);

            try {
                const response = await axios.post(updateProduct, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                this.$swal('Éxito', 'Imagen actualizada con éxito', 'success');
                this.consultarProductos();
            } catch (error) {
                console.error('ERROR AL SUBIR IMAGENES A FIREBASE', error);
                this.$swal('Error', 'Hubo un problema al actualizar la imagen.', 'error');
            }
        },
    },
    mounted() {
        this.consultarProductos();
    }
};
</script>

<style scoped></style>
