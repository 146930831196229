import { apiUsuarios } from "@/boot/axios";
import { auth } from "@/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  set,
  ref,
  getDatabase,
  get,
  child,
  update,
  push,
  onValue,
} from "firebase/database";
import { sha256 } from "js-sha256";

const state = {
  usuarios: [],
  roles: [],
  usuarioActual: null,
};

const getters = {
  getUsuarioActual() {
    return state.usuarioActual;
  },
  getUsuarios() {
    return state.usuarios;
  },
};

const mutations = {
  setUsuarioActual(state, usuario) {
    state.usuarioActual = usuario;
    console.log("Guardado");
    console.log(state.usuarioActual);
  },
  setUsuarios(state, nuevosUsuarios) {
    state.usuarios = nuevosUsuarios;
  },
  setRoles(state, nuevosRoles) {
    state.roles = nuevosRoles;
    console.log(state.roles);
  },
};

const actions = {
  async guardarUsuario({ commit }, dataUsuario) {
    const { nombre, correo, contraseña, uid, rol } = dataUsuario;
    let contraseñaEncriptada = sha256.create();
    contraseñaEncriptada.update(contraseña);
    contraseñaEncriptada.hex();
    console.log(contraseñaEncriptada.hex());
    try {
      const db = getDatabase();
      await set(
        ref(
          db,
          `projects/procesamientoImagenes/users/${uid}`
        ),
        {
          email: correo,
          hashedPassword: contraseñaEncriptada.hex(),
          name: nombre,
          rol: rol,
          status: "active",
        }
      );
    } catch (error) {
      throw {
        code: "Error PIMG-6",
        msg: "Ocurrió un error al guardar el nuevo usuario. Inténtalo más tarde",
      };
    }
  },
  async consultarRoles({ commit }) {
    try {
      
      const db = getDatabase();
      const rolesRef = ref(db, `projects/procesamientoImagenes/rols`);
      onValue(rolesRef, (snapshot) => {
        const nuevosRoles = [];
        for (const [id, data] of Object.entries(snapshot.val())) {
          nuevosRoles.push({
            ...data,
          });
        }
        commit("setRoles", nuevosRoles);
      });
    } catch (error) {
      throw {
        code: "Error PIMG-8",
        msg: "Ocurrió un error al consultar roles. Inténtalo más tarde",
      };
    }
  },
  async consultarUsuarios({ commit, state }) {
    try {
      const db = getDatabase();
      const usuariosRef = ref(db, `projects/procesamientoImagenes/users`);
      onValue(usuariosRef, (snapshot) => {
        const nuevosUsuarios = [];
        for (const [id, data] of Object.entries(snapshot.val())) {
          nuevosUsuarios.push({
            id: id,
            ...data,
          });
        }
        commit("setUsuarios", nuevosUsuarios);
        console.log(state.usuarios);
      });
    } catch (error) {
      throw {
        code: "Error PIMG-9",
        msg: "Ocurrió un error al consultar usuarios. Inténtalo más tarde",
      };
    }
  },
  async buscarUsuarioAutenticado({ commit }, userId) {
    const dbRef = ref(getDatabase());
    const snapshot = await get(
      child(
        dbRef,
        `projects/procesamientoImagenes/users/${userId}`
      )
    );
    const usuarioEncontrado = snapshot.val();
    console.log(usuarioEncontrado);
    return usuarioEncontrado;
  },
  async iniciarSesionCorreoContraseña({ dispatch }, loginData) {
    const { correo, contraseña } = loginData;
    try {
      console.log(correo);
      console.log(contraseña);
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        correo,
        contraseña
      );

      const uid = userCredential.user.uid;
      console.log(userCredential.user);
      const encontrado = await dispatch("buscarUsuarioAutenticado", uid);
      console.log("ENCONTRADO");
      console.log(encontrado);
      if (!encontrado) {
        await dispatch("cerrarSesionUsuario");
        throw {
          code: "Correo y/o contraseña inválidos",
        };
      } else if(encontrado?.status != "active"){
        await dispatch("cerrarSesionUsuario");
        throw {
          code: "Usuario inactivo",
        };
      }
      return encontrado;
    } catch (error) {
      console.log(error);
      const errorCode = error.code;
      console.log(errorCode);
      if (errorCode == "auth/too-many-requests") {
        throw {
          code: "Demasiados intentos. Inténtalo más tarde",
        };
      } else if (errorCode == "Usuario inactivo") {
        throw {
          code: "Usuario inactivo",
        };
      } else if (errorCode) {
        throw {
          code: "Correo y/o contraseña inválidos",
        };
      } else {
        throw {
          code: "Error PIMG-5",
          msg: "Ocurrió un error inesperado al iniciar sesión. Inténtalo más tarde",
        };
      }
    }
  },
  async cerrarSesionUsuario({ commit }) {
    try {
      const auth = getAuth();
      commit("setUsuarioActual", null);
      await signOut(auth);
    } catch (error) {
      console.log(error);
    }
  },
  async registrarUsuario({ commit }, dataRegistroUsuario) {
    const { correo, contraseña, nombre } = dataRegistroUsuario;

    try {
      const response = await apiUsuarios({
        method: "POST",
        url: "/registrar-usuario",
        data: {
          correo,
          contraseña,
          nombre,
        },
      });

      console.log(response.data);

      return response.data.data;
    } catch (error) {
      throw error.response?.data;
    }
  },
  async actualizarUsuario({ commit }, dataActualizarUsuario) {
    const { correo, contraseña, nombre, uid } = dataActualizarUsuario;

    try {
      const response = await apiUsuarios({
        method: "POST",
        url: "/actualizar-usuario",
        data: {
          correo,
          contraseña,
          nombre,
          uid,
        },
      });

      console.log(response.data);

      return response.data.data;
    } catch (error) {
      throw error.response?.data;
    }
  },
  async consultarDatosUsuario({ commit }, correo) {
    try {
      const response = await apiUsuarios({
        method: "POST",
        url: "/consultar-usuario",
        data: {
          correo,
        },
      });

      console.log(response);

      return response.data.data.uid;
    } catch (error) {
      throw error.response?.data;
    }
  },
  async buscarUsuarioBD({ commit }, uid) {
    try {
      const response = await apiUsuarios({
        method: "POST",
        url: "/buscar-usuario-bd",
        data: {
          uid,
        },
      });

      console.log(response);

      return response.data.data;
    } catch (error) {
      throw error.response?.data;
    }
  },
  sesionUsuarioActual({ commit }) {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
      onAuthStateChanged(
        auth,
        (user) => {
          console.log(state.usuarioActual);
          console.log(user);
          if (!user) {
            commit("setUsuarioActual", null);
          }
          resolve(user);
        },
        (e) => reject(e)
      );
    });
  },
  async guardarUsuarioAutenticado({ commit, dispatch }, uid) {
    try {
      const dataUsuario = await dispatch("buscarUsuarioAutenticado", uid);
      console.log(dataUsuario);
      if (dataUsuario) {
        const objetoUsuario = {
          uid: uid,
          email: dataUsuario.email,
          name: dataUsuario.name,
          rol: dataUsuario.rol,
          status: dataUsuario.status,
        };
        commit("setUsuarioActual", objetoUsuario);
        if(dataUsuario.rol && dataUsuario.rol == 1){
          dispatch("menu/setAdminMenu", true, { root: true });
          console.log("esAdmin");
        }else{
          dispatch("menu/setAdminMenu", false, { root: true });
          console.log("noEsAdmin");
        }
      } else {
        await dispatch("cerrarSesionUsuario");
      }
    } catch (error) {
      console.log(error);
    }
  },
  async actualizarDatosUsuarioBD({ commit }, dataActualizarUsuario) {
    const { rol, nombre, uid, estatus } = dataActualizarUsuario;
    
    try {
      const db = getDatabase();

      
      const dataUsuario = {
        name: nombre,
        status: estatus,
        rol: rol,
      };
    
      const updates = {};
      updates[`projects/procesamientoImagenes/users/${uid}/name`] = nombre;
      updates[`projects/procesamientoImagenes/users/${uid}/status`] = estatus;
      updates[`projects/procesamientoImagenes/users/${uid}/rol`] = rol;
    
      return update(ref(db), updates);
    } catch (error) {
      throw {
        code: "Error PIMG-7",
        msg: "Ocurrió un error al actualizar los datos del usuario. Inténtalo más tarde",
      };
    }
    
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
