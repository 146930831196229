import { v4 as uuidv4 } from "uuid";

import { set, ref, getDatabase, onValue, remove, get, child } from "firebase/database";

const state = {
  destinatarios: [],
};

const getters = {};

const mutations = {
  setDestinatarios(state, nuevosDestinatarios) {
    state.destinatarios = nuevosDestinatarios;
    console.log(state.destinatarios);
  },
};

const actions = {
  async guardarDestinatario({ commit }, dataDestinatario) {
    const { correo, id } = dataDestinatario;
    let idDestinatario = "";
    if (id) {
      idDestinatario = id;
    } else {
      idDestinatario = uuidv4();
    }
    console.log(idDestinatario);
    try {
      const db = getDatabase();
      await set(
        ref(
          db,
          `projects/procesamientoImagenes/destinatarios/${idDestinatario}`
        ),
        {
          email: correo,
        }
      );
    } catch (error) {
      console.log(error);
      throw {
        code: "Error PIMG-10",
        msg: "Ocurrió un error al guardar destinatario. Inténtalo más tarde",
      };
    }
  },
  async consultarDestinatarios({ commit }) {
    try {
      const db = getDatabase();
      const destinatariosRef = ref(
        db,
        `projects/procesamientoImagenes/destinatarios`
      );
      onValue(destinatariosRef, (snapshot) => {
        const nuevosDestinatarios = [];
        if (snapshot.val()) {
          for (const [id, data] of Object.entries(snapshot.val())) {
            nuevosDestinatarios.push({
              id: id,
              ...data,
            });
          }
        }
        commit("setDestinatarios", nuevosDestinatarios);
      });
    } catch (error) {
      throw {
        code: "Error PIMG-11",
        msg: "Ocurrió un error al consultar destinatarios. Inténtalo más tarde",
      };
    }
  },
  async borrarDestinatario({ commit }, idDestinatario) {
    try {
      const dbRef = getDatabase();
      const destinatarioRef = ref(
        dbRef,
        `projects/procesamientoImagenes/destinatarios/${idDestinatario}`
      );

      await remove(destinatarioRef);
    } catch (error) {
      throw {
        code: "Error PIMG-12",
        msg: "Ocurrió un error al eliminar el destinatario. Inténtalo más tarde",
      };
    }
  },
  async obtenerDestinatariosImagenes() {
    const destinatariosFinales = [];
    const destinatariosRef = ref(getDatabase());
    const snapshot = await get(
      child(destinatariosRef, `projects/procesamientoImagenes/destinatarios`)
    );

    if (snapshot.exists()) {
      for (const [id, data] of Object.entries(snapshot.val())) {
        destinatariosFinales.push(data.email);
      }
      
    } 

    return destinatariosFinales;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
