<template>
    <Breadcrumbs title="Subir Imagenes"/>
     <div class="container-fluid">
             <div class="row starter-main">
                <UploadImages/>

             </div>
     </div>
  </template>
  <script>
import UploadImages from '@/components/uploadImages.vue';
  
  export default {
         components:{
            UploadImages,  
         }
  }
  </script>