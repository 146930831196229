import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from 'firebase/firestore';
import { getStorage} from "firebase/storage";


// const firebaseConfig = {
//   apiKey: "AIzaSyAjz7XcFSiRd4MghH04iW_VqXgIGXydteo",
//   authDomain: "procesador-imagenes.firebaseapp.com",
//   projectId: "procesador-imagenes",
//   storageBucket: "procesador-imagenes.appspot.com",
//   messagingSenderId: "921852955739",
//   appId: "1:921852955739:web:1ad1e2971c99221889437e"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCGlstIABJxdA4k36VixzhdyvT1Q3Vibu8",
  authDomain: "super-kompras.firebaseapp.com",
  databaseURL: "https://super-kompras.firebaseio.com",
  projectId: "super-kompras",
  storageBucket: "super-kompras.appspot.com",
  messagingSenderId: "401245304429",
  appId: "1:401245304429:web:e5eb28c1c0b51a0053f164"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const rtdb = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);

export { firebaseApp, auth, db, rtdb, storage };