<template>
  <div>
    <div class="logo-wrapper">
      <logoView />
      <div class="back-btn" @click="toggle_sidebar">
        <i data-feather="grid"><vue-feather type="grid"></vue-feather></i>
      </div>
      <div class="toggle-sidebar icon-box-sidebar" @click="toggle_sidebar">
        <vue-feather
          class="status_toggle middle sidebar-toggle"
          type="grid"
        ></vue-feather>
      </div>
    </div>
    <div class="logo-icon-wrapper">
      <router-link to="/">
        <div class="icon-box-sidebar">
          <i data-feather="grid">
            <vue-feather type="grid"></vue-feather
          ></i></div
      ></router-link>
    </div>
    <div class="logo-wrapper">
      <div class="profile-title">
        <div class="container">
          <div class="d-lg-flex d-block align-items-center flex-column">
            <img class="img-70 rounded-circle" alt="" :src="urlLogoUsuario" />
            <div class="flex-grow-1">
              <h3 class="mb-1 f-20 txt-primary">
                {{ usuarioActual?.name }}
              </h3>
              <p class="f-12 mb-0 txt-secondary">
                {{ usuarioActual?.email }}
              </p>
              <p class="f-15 mb-0 txt-light">{{ rolActual  }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <navView />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import logoView from "./logoView.vue";
import navView from "./navView.vue";
export default {
  components: {
    logoView,
    navView,
  },
  data() {
    return {
      urlLogoUsuario: "",
      idRoles: [
        {
          id: 1,
          color: "#F97316",
          nombre: "Administrador",
        },

        { id: 2, color: "#3B82F6", nombre: "Compras" },
        { id: 3, color: "#7E22CE", nombre: "TI" },
        {
          id: 4,
          color: "#EAB308",
          nombre: "E-commerce",
        },
      ],
      rolActual:""
    };
  },
  created() {
    this.obtenerUrlLogoUsuario();
    if(this.usuarioActual?.rol){

      this.rolActual = this.getRolUsuario(this.usuarioActual?.rol);
    }
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      activeoverlay: (state) => state.menu.activeoverlay,
    }),
    ...mapState("autenticacion", ["usuarioActual"]),
  },
  watch: {
    usuarioActual(nuevoUsuario, viejoUsuario) {
      this.obtenerUrlLogoUsuario();
      if(nuevoUsuario?.rol){
        this.rolActual = this.getRolUsuario(nuevoUsuario?.rol);
      }
    },
  },
  methods: {
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
      this.$store.state.menu.activeoverlay = false;
    },
    getRolUsuario(id){
      console.log("Buscando rol");
      const rolEncontrado = this.idRoles.find((rol => {
        return rol.id == id;
      }))
      console.log(rolEncontrado);
      console.log(rolEncontrado.nombre);
      return rolEncontrado.nombre;
    },
    obtenerUrlLogoUsuario() {
      if (this.usuarioActual) {
        if (this.usuarioActual?.foto) {
          this.urlLogoUsuario = this.usuarioActual?.foto;
        } else if (this.usuarioActual?.name) {
          this.urlLogoUsuario = `https://ui-avatars.com/api/?name=${this.usuarioActual?.name}&background=random`;
        } else {
          this.urlLogoUsuario = `https://ui-avatars.com/api/?name=${this.usuarioActual?.email}&background=random`;
        }
      }
    },
  },
};
</script>
