<template>
    <nav class="sidebar-main">
        
        <navMenu/>
         
    </nav>
</template>
<script>

import navMenu from "./navMenu.vue"
export default {
    components:{navMenu },
    
}
</script>