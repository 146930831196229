import Swal from "sweetalert2";


export const sweetAlertMixin = {
  methods: {
    showAlert(title, text, icon, confirmButtonText, cancelButtonText, imageUrl) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-primary me-3"
        },
        buttonsStyling: false
      });
      
      let options = {
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        reverseButtons: true
      };

      // Agregar la imagen si imageUrl está definido
      if (imageUrl) {
        options = Object.assign(options, {
          imageUrl: imageUrl,
          imageWidth: 200, // ajusta el ancho según sea necesario
          imageHeight: 200, // ajusta la altura según sea necesario
          imageAlt: "Custom image"
        });
      }

      return swalWithBootstrapButtons.fire(options).then((result) => {
        if (result.isConfirmed) {
          return swalWithBootstrapButtons.fire({
            title: "ELIMINADO",
            text: "Producto eliminado exitosamente",
            icon: "success"
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          return swalWithBootstrapButtons.fire({
            title: "Cancelado",
            text: "",
            icon: "error",
            showConfirmButton: false
          });
        }
      });
    }
  }
};