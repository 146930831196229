<template>
  <nav>
   
  </nav>
  <router-view/>
</template>

<style lang="scss">

</style>
