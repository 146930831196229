<template>
        <Breadcrumbs title="Dashboard" />
        <div class="container-fluid">
                <div class="row starter-main">
                        <dashboard />
                </div>
        </div>
</template>
<script>

import dashboard from "@/components/dashboard.vue"
export default {
        components: {
                dashboard
        }
}
</script>