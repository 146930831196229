<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      type: "password",
      errors: [],
      inputs: {
        correo: {
          data: "",
          errorMessage: "",
        },
        contraseña: {
          data: "",
          errorMessage: "",
        },
      },
      formSubmitted: false,
    };
  },
  methods: {
    ...mapActions("autenticacion", ["iniciarSesionCorreoContraseña"]),
    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    validarFormularioLogin() {
      this.errors = [];
      const emailRegex =
        /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;

      this.formSubmitted = true;
      let values = this.inputs;
      console.log(values);

      !emailRegex.test(values.correo.data)
        ? (values.correo.errorMessage = "Correo inválido")
        : (values.correo.errorMessage = "");

      values.contraseña.data.length <= 0
        ? (values.contraseña.errorMessage = "Proporciona una contraseña")
        : values.contraseña.data.length < 6
        ? (values.contraseña.errorMessage =
            "Contraseña debe ser mayor a 6 caracteres")
        : (values.contraseña.errorMessage = "");

      for (const [key, input] of Object.entries(this.inputs)) {
        if (input.errorMessage.length) {
          this.errors.push(input.errorMessage);
        }
      }

      console.log(this.errors);
      if (this.errors.length == 0) {
        this.iniciarSesion();
      }
    },
    async iniciarSesion(){
      try {
        const usuarioEncontrado = await this.iniciarSesionCorreoContraseña({correo: this.inputs.correo.data, contraseña: this.inputs.contraseña.data })
        if(usuarioEncontrado){
          console.log(usuarioEncontrado);
          this.$router.push("/");
        }
      } catch (error) {
        console.log(error);
        this.$swal({ 
          icon: error.msg ? "error" : "warning",
          title: error.code ? error.code : "Error",
          text: error.msg ? error.msg : null,
        });
      }
    }
  },
};
</script>
<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card">
          <div>
            <div>
              <a class="logo">
                <img
                  class="img-fluid"
                  src="../assets/images/logo-SK.png"
                  alt="login page"
                />
              </a>
            </div>
            <div class="login-main">
              <form
                class="theme-form needs-validation"
                @submit.prevent="validarFormularioLogin"
              >
                <h4 class="text-center">Iniciar Sesión</h4>
                <p class="text-center">Ingresa tu correo y contraseña</p>
                <div class="form-group">
                  <label class="col-form-label">Correo Electrónico</label>
                  <input
                    class="form-control"
                    type="email"
                    placeholder="Ingresa tu correo electrónico"
                    id="correo"
                    name="correo"
                    v-model="inputs.correo.data"
                    v-bind:class="
                      formSubmitted
                        ? inputs.correo.errorMessage.length
                          ? 'is-invalid'
                          : 'is-valid'
                        : ''
                    "
                  />
                  <div
                    class="invalid-feedback"
                    v-if="inputs.correo.errorMessage.length"
                  >
                    {{ inputs.correo.errorMessage }}
                  </div>
                  <div class="valid-feedback" v-else>Correcto!</div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Contraseña</label>
                  <div class="form-input position-relative">
                    <input
                      :type="type"
                      class="form-control"
                      placeholder="Ingresa tu contraseña"
                      id="contraseña"
                      name="contraseña"
                      v-model="inputs.contraseña.data"
                      v-bind:class="
                        formSubmitted
                          ? inputs.contraseña.errorMessage.length
                            ? 'is-invalid'
                            : 'is-valid'
                          : ''
                      "
                    />

                    <div class="show-hide">
                      <span
                        :class="[type == 'password' ? 'show' : '']"
                        @click="showPassword"
                      >
                      </span>
                    </div>
                    <div
                      class="invalid-feedback"
                      v-if="inputs.contraseña.errorMessage.length"
                    >
                      {{ inputs.contraseña.errorMessage }}
                    </div>
                    <div class="valid-feedback" v-else>Correcto!</div>
                  </div>
                </div>
                <div class="form-group mb-0 mt-4">
                  <div class="text-end mt-3">
                    <button
                      class="btn btn-primary btn-block w-100"
                      type="submit"
                    >
                      Acceder
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
