import { createStore } from 'vuex'
import menu from "./modules/menu"
import layout from "./modules/layout"
import datatable from './modules/datatable'
import autenticacion from './modules/autenticacion'
import destinatarios from './modules/destinatarios'
import socket from './modules/socket'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    menu,
    layout,
    datatable,
    autenticacion,
    destinatarios,
    socket
  }
})
